import React, { Component } from "react";
import Router from './routes';

class App extends Component {

  render() {
    return (
      < Router />
    );
  }
}

export default App;
