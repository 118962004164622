import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import brand from '../../../assets/img/brand.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'

import { Container, Card, Row, Col, Input, Img, Alert } from 'reacthalfmoon';
import Wrapper from '../../../components/wrapper';

import api from '../../../services/api';
import { login, refresh } from '../../../services/auth';

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        error: ""
    };

    handleSignIn = async e => {
        e.preventDefault();

        const { email, password } = this.state;
        if (!email || !password) {
            this.setState({ error: "Preencha todos os campos para logar!" });
        } else {
            this.setState({ error: "" });
            try {
                const response = await api.post("/usuario/login", { email, senha: password });
                login(response.data.accessToken);
                refresh(response.data.refreshToken);

                console.log(response);
                this.props.history.push('/app');

            } catch (err) {
                this.setState({
                    error: "Ocorreu um problema. Verifique as suas credencias."
                });
            }
        }
    };

    render() {
        return (
            <Wrapper content={
                <Container>
                    <Row>
                        <Col lg={6} offset={{ lg: 3 }} className="container">

                            <Card className="mw-full position-relative">
                                <Row className="m-5">

                                    <Col sm={12} lg={12} className="">

                                        <Row>
                                            <Img src={brand} />
                                        </Row>

                                        <hr />

                                        <Row>
                                            <h5 className="text-primary text-center"> Login </h5>
                                        </Row>

                                        <form onSubmit={this.handleSignIn}>

                                            <Row>
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faEnvelope}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Seu E-mail"
                                                        type="text"
                                                        onChange={e => this.setState({ email: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />

                                            <Row>
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faKey}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Senha"
                                                        type="password"
                                                        onChange={e => this.setState({ password: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />

                                            {this.state.error ?
                                                <Alert dismissible color="danger" className="my-5">
                                                    {this.state.error}
                                                </Alert>
                                                : null
                                            }

                                            <br />

                                            <Row >
                                                <Input type="submit" className="bg-primary text-white btn-block" value="Logar" />
                                            </Row>

                                        </form>
                                        <br />
                                        <hr />

                                        <p className="text-center "> Ou </p>
                                        <Row >
                                            <Link to="/signup" className="btn btn-secondary text-white btn-block mt-5">
                                                Criar uma Conta
                                        </Link>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            } />
        );
    }

}

export default withRouter(SignIn);