export const TOKEN_KEY = "accessToken";
export const REF_TOKEN_KEY = "refreshToken";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefToken = () => localStorage.getItem(REF_TOKEN_KEY);
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const refresh = token => {
  localStorage.setItem(REF_TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REF_TOKEN_KEY);
};