import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import Wrapper from '../../../components/wrapper';

import { Container, Card, Row, Col, Input, Alert, stickyAlert } from 'reacthalfmoon';

import SelectTheme from './SelectTheme';
import api from '../../../services/api';

class SignUp extends Component {

    state = {
        email: "",
        nome: "",
        senha: "",
        senha2: "",
        tema: "",

        error: ""
    };

    handleSignUp = async e => {
        e.preventDefault();

        const { email, senha, senha2, nome, tema } = this.state;

        console.log(this.state);

        if (!email || !senha || !senha2 || !nome || !tema) {
            this.setState({ error: "Preencha todos os campos para criar a conta!" });

        } else {
            this.setState({ error: "" });

            try {
                await api.post("/usuario", { nome, email, senha, tema });

                stickyAlert({
                    title: "Usuario Criado com Sucesso!",
                    content: "Aguarde que te levaremos até o login!",
                    color: "success",
                    filled: "lm",
                    dismissible: true
                });

                setTimeout(() =>  this.props.history.push('/'), 3000);

            } catch (err) {
                console.log(err);

                this.setState({
                    error: "Ocorreu um problema. Verifique as suas credencias."
                });
            }
        }
    };

    render() {
        return (
            <Wrapper content={

                <Container>
                    <Row>
                        <Col lg={6} offset={{ lg: 3 }}>
                            <Card>
                                <Row className="m-5">

                                    <Col sm={12} lg={12} className="">

                                        <Row>
                                            <h5>  Começe a usar hoje mesmo o </h5> <h5 className="ml-5 text-primary"> Nekto </h5>
                                        </Row>

                                        <hr />
                                        <br />

                                        <form onSubmit={this.handleSignUp}>

                                            <Row>
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faEnvelope}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Seu E-mail"
                                                        type="text"
                                                        onChange={e => this.setState({ email: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />

                                            <Row>
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faUser}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Seu Nome Completo"
                                                        type="text"
                                                        onChange={e => this.setState({ nome: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />


                                            <Row className="mb-5">
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faKey}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Senha"
                                                        type="password"
                                                        onChange={e => this.setState({ senha: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col size={1} className="text-right pt-5 pr-10">
                                                    <FontAwesomeIcon icon={faKey}> </FontAwesomeIcon>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        placeholder="Confirmar Senha"
                                                        type="password"
                                                        onChange={e => this.setState({ senha2: e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />

                                            <SelectTheme onThemeChange={theme => this.setState({ tema: theme })} />
                                            <br />
                                            <br />

                                            {this.state.error ?
                                                <div>
                                                    <Alert dismissible color="danger" className="my-5">
                                                        {this.state.error}
                                                    </Alert>
                                                    <br />
                                                </div>
                                                : null
                                            }

                                            <Row >
                                                <Input type="submit" className="bg-primary text-white btn-block" value="Criar Conta" />
                                            </Row>

                                            <br />
                                        </form>

                                        <hr />
                                        <br />

                                        <Row >
                                            <Link to="/" className="btn btn-secondary text-white btn-block mt-5">
                                                Ir para o Login
                                </Link>
                                        </Row>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            } />

        );
    }

}

export default withRouter(SignUp);