import React from "react";

import { SidebarTitle } from 'reacthalfmoon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const UserInfo = (props) => {
    return (
        <SidebarTitle> <FontAwesomeIcon icon={faUser} className="mx-10"> </FontAwesomeIcon> { props.userName} </SidebarTitle>
    );
}

export default UserInfo;
