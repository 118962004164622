import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons'

import { Row, Col } from 'reacthalfmoon';

import Cookies from 'js-cookie';

const SelectTheme = (props) => {
    let dm = Cookies.get('halfmoon_preferredMode') === 'dark-mode' ? 'Dark' : 'Light';

    const [ theme, setTheme ] = useState(dm);

    const onChangeRadio = (event) => {
        if ( event.target.value === 'Light' ) {
            Cookies.set('halfmoon_preferredMode', 'light-mode');
            document.body.classList.remove('dark-mode');
        }else{
            Cookies.set('halfmoon_preferredMode', 'dark-mode');
            document.body.classList.add('dark-mode');
        }

        setTheme(event.target.value);
        props.onThemeChange(event.target.value);
    }

    return (
        <Row>
            <Col size={1} className="text-right pt-5 pr-10">
                <FontAwesomeIcon icon={faPaintBrush}> </FontAwesomeIcon>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <Row className="pl-10 pt-5">
                            <div className="custom-radio">
                                <input type="radio" name="radio-set-1" id="radio-1" value="Dark" checked={theme === 'Dark'} onChange={onChangeRadio} />
                                <label htmlFor="radio-1"> Dark </label>
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="pl-10 pt-5">
                            <div className="custom-radio">
                                <input type="radio" name="radio-set-1" id="radio-2" value="Light" checked={theme === 'Light'} onChange={onChangeRadio} />
                                <label htmlFor="radio-2"> Light </label>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default SelectTheme;
