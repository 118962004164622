import React from "react";

import { SidebarItem, stickyAlert } from 'reacthalfmoon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { useHistory } from "react-router-dom";

import api from '../../../../services/api';
import { logout, getRefToken } from '../../../../services/auth';

const BotaoLogout = (props) => {
    const history = useHistory();
    
    const realizarLogout = async () => {
        await api.post("/usuario/logout", { refreshToken: getRefToken() });
        logout();

        stickyAlert({
            title: "Logout efetuado com sucesso!",
            content: "Você sairá do Nekto em alguns segudos. Esperamos que você volte logo!",
            color: "success",
            filled: "lm",
            dismissible: true
        });

        setTimeout(() => history.push('/'), 3000);
    }

    return (
        <SidebarItem onClick={realizarLogout}> <FontAwesomeIcon icon={faSignOutAlt} className="mx-10"> </FontAwesomeIcon> Sair </SidebarItem>
    );
}

export default BotaoLogout;
