import React, { Component } from "react";
import "./style.css";

import nada_aqui from '../../../assets/img/nada_aqui.svg';

import { Container, Card, Row, Col, Img } from "reacthalfmoon";
import { withRouter } from "react-router-dom";

import Wrapper from '../../../components/wrapper';

class Home extends Component {
    render() {
        return (
            <Wrapper content={
                <Container>
                    <Row>
                        <Col md={4}>
                            <Card className="page-list">
                                <h5 className="text-primary mt-0"> Meus Contatos </h5>
                                <hr/>
                                <p className="text-muted text-center"> Nenhum contato </p>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card className="page-list">
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <Col size={4} offset={{ md: 4 }}>
                                    <Img fluid src={nada_aqui} />
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }/>
        );
    }
}

export default withRouter(Home);