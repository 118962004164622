import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserPlus, faUserCheck } from '@fortawesome/free-solid-svg-icons';

import { Link } from "react-router-dom";

import logo from '../../assets/img/logo.svg';

import { PageWrapper, Navbar, NavbarContent, NavbarBrand, Img } from 'reacthalfmoon';
import { Sidebar, SidebarMenu, SidebarBrand, SidebarItem, SidebarDivider } from 'reacthalfmoon';
import { Button, ContentWrapper, DarkmodeSwitch } from 'reacthalfmoon';

import Cookies from 'js-cookie';

import UserInfo from '../common/SidebarComponents/UserInfo';
import BotaoLogout from '../common/SidebarComponents/BotaoLogout';

import api from '../../services/api';
import { getToken, isAuthenticated } from '../../services/auth';
import parseJwt from '../../services/jwt';

function Wrapper(props) {

    let dm = Cookies.get('halfmoon_preferredMode') === 'dark-mode';
    if (dm) document.body.classList.add('dark-mode');

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [darkmode, setDarkMode] = useState(dm);

    const [userName, setUserName] = useState('');

    const toggleDarkMode = () => {
        if (Cookies.get('halfmoon_preferredMode') === 'dark-mode') {
            Cookies.set('halfmoon_preferredMode', 'light-mode');
            document.body.classList.remove('dark-mode');
        } else {
            Cookies.set('halfmoon_preferredMode', 'dark-mode');
            document.body.classList.add('dark-mode');
        }

        setDarkMode(!darkmode);
    }
    
    async function recuperaDadosUsuario() {
        let req = await api.get("/usuario/" + parseJwt(getToken()).id);

        // console.log(req);
        let name = req.data['nome'].split(' ')[0];

        setUserName(name.substring(0, 15) + ( name.length > 14 ? '...' : '' ));
    }
    
    useEffect(() => {
        
        if (isAuthenticated()) {
            recuperaDadosUsuario();
        }
        
    }, []);
    
    (() => !userName && isAuthenticated() ? recuperaDadosUsuario() : null)();

    return (
        <PageWrapper withSidebar sidebarType="overlayed-all" isSidebarOpen={isSidebarOpen} toggle={() => { setIsSidebarOpen(!isSidebarOpen) }} withNavbar withStickyAlert>

            <Navbar className="bg-secondary">
                <NavbarContent>
                    <Button color="secondary" onClick={() => { setIsSidebarOpen(!isSidebarOpen) }}>
                        <FontAwesomeIcon icon={faBars}> </FontAwesomeIcon>
                    </Button>

                    <NavbarBrand className="ml-10 text-primary ml-auto">
                        <Img src={logo} alt="brand" />
                        Nekto
                    </NavbarBrand>

                </NavbarContent>
            </Navbar>

            <Sidebar>
                <SidebarMenu>
                    <SidebarBrand>
                        <Img src={logo} alt="brand" />
                        Nekto
                    </SidebarBrand>
                </SidebarMenu>

                {/* <SidebarContent>
                    <Input type="text" placeholder="Search" />
                </SidebarContent>
                
                <SidebarTitle>Getting started</SidebarTitle>
                <SidebarDivider />
                <SidebarItem active>Installtion</SidebarItem>
                <SidebarItem>CLI Commands</SidebarItem>
                <SidebarTitle>Components</SidebarTitle>
                <SidebarDivider />
                <SidebarItem>File Explorer</SidebarItem>
                <SidebarItem>Spreadsheet</SidebarItem>
                <SidebarItem>Maps</SidebarItem> */}

                {isAuthenticated() ?
                    <div>
                        <UserInfo userName={userName} />
                        <br />

                        <BotaoLogout />
                        <SidebarDivider />

                    </div>

                    :

                    <div>
                        <Link to="/"> <SidebarItem> <FontAwesomeIcon icon={faUserCheck} className="mx-10"> </FontAwesomeIcon> Logar </SidebarItem> </Link>
                        <Link to="/signup"> <SidebarItem> <FontAwesomeIcon icon={faUserPlus} className="mx-10"> </FontAwesomeIcon> Criar Conta </SidebarItem> </Link>
                        <br />

                        <SidebarDivider />

                    </div>}

                <br />
                <SidebarItem>
                    <DarkmodeSwitch className="mx-10" checked={darkmode} toggle={toggleDarkMode} />
                </SidebarItem>

            </Sidebar>

            <ContentWrapper className="container">

                {props.content}

            </ContentWrapper>

        </PageWrapper>
    )
}

export default Wrapper;